import * as React from "react";
import "./style.scss";

import Modal from "react-bootstrap/Modal";
import Select from "react-select";

import buildQuestionsTree from "../../utils/buildQuestionsTree";
import QuestionForm from "./QuestionForm";

import Question from "./Question";
import client from "./../../utils/client";
import { useAsync } from "../../hooks/useAsync";

// toInt :: String -> Number
const toInt = (a) => parseInt(a, 10);

// cut :: String -> String
const cut = (n, s) => s.substr(0, n);

const customSelectStyles = {
  container: (provided) => ({
    ...provided,
    display: "flex",
    justifyContent: "center",
  }),
  control: (provided) => ({
    ...provided,
    width: 500,
  }),
  option: (provided, state) => ({
    ...provided,
  }),
  menu: (provided) => ({
    ...provided,
    width: 500,
  }),
};

function Questions() {
  const [questionsList, setQuestionsList] = React.useState([]);
  const [currentQuestion, setCurrentQuestion] = React.useState(null);
  const [options, setOptions] = React.useState([]);
  const [showModal, setShowModal] = React.useState("none");
  const [pathSteps, setPathSteps] = React.useState([]);
  const [filterOption, setFilterOption] = React.useState(null);
  // If there are no Question created this must be the first (default)
  const [nextJunctionId, setNextJunctionId] = React.useState(1);
  const [previousNode, setPreviousNode] = React.useState(null);
  const [questionType, setQuestionType] = React.useState(null);
  const [error, setError] = React.useState(null);
  const { data, run, isSuccess, isLoading } = useAsync();

  if (isSuccess && !pathSteps.length) {
    const {
      data: { data: entrywaysResults },
    } = data;
    const entryways = entrywaysResults.map((entryway) => ({
      value: entryway._id,
      label: entryway.name.trim(),
    }));

    setFilterOption(entryways[0]);
    setPathSteps(entryways);
  }

  const tree = buildQuestionsTree(questionsList);

  const getPathStepsList = React.useCallback(() => {
    return client.get(`path-steps`);
  }, []);

  const buildOptions = (questions, huts) => {
    const questionsOptions = questions.map((question) => {
      return {
        value: question.currentNode,
        label: `${question.currentNode} - ${cut(50, question.details)}...`,
      };
    });
    const hutsOptions = huts.map((hut) => ({
      value: hut._id,
      label: `${hut.hutType} / ${hut.hutSubType}`,
    }));

    const options = [
      { label: "Questions", options: [...questionsOptions] },
      { label: "Huts", options: [...hutsOptions] },
    ];
    return options;
  };

  React.useEffect(() => {
    run(getPathStepsList());
  }, [run, getPathStepsList]);

  React.useEffect(() => {
    if (pathSteps.length !== 0) {
      Promise.all([
        client.get(`questions?path_name=${filterOption.value}`),
        client.get(`huts?path_name=${filterOption.value}`),
      ]).then(
        ([
          {
            data: { data: questions },
          },
          {
            data: { data: huts },
          },
        ]) => {
          // Process both results
          const options = buildOptions(questions, huts);
          // Set next Junction ID
          const availableQuestions = options[0].options;
          if (availableQuestions.length !== 0) {
            const latestJunctionId = toInt(
              availableQuestions[availableQuestions.length - 1].value
            );
            setNextJunctionId(latestJunctionId + 1);
          } else {
            setNextJunctionId(1);
          }
          setOptions(options);
          setQuestionsList(questions);
        }
      );
    }
  }, [filterOption, pathSteps]);

  const getQuestionsList = (pathName) => {
    client
      .get(`questions?path_name=${pathName}`)
      .then(({ data: { data: questions } }) => {
        if (questions.length !== 0) {
          const latestJunctionId = toInt(
            questions[questions.length - 1].currentNode
          );
          setNextJunctionId(latestJunctionId + 1);
          setQuestionsList(questions);
        } else {
          setNextJunctionId(1);
          setQuestionsList(questions);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function deleteQuestion(id) {
    client
      .delete(`questions/${id}`, {
        data: {
          id,
        },
      })
      .then(() => {
        getQuestionsList(filterOption.value);
      })
      .catch((err) => {
        setError(
          new Error(
            "This question has other children questions, hence you can not remove it."
          )
        );
        setShowModal("error");
      });
  }

  function openModal({ id = null, currentNode = null }) {
    if (id) {
      setShowModal("edit");
      const questionToEdit = questionsList.find(
        (question) => question._id === id
      );
      setCurrentQuestion(questionToEdit || null);
    } else if (currentNode) {
      setShowModal("edit");
      const questionToEdit = questionsList.find(
        (question) => question.currentNode === currentNode
      );
      setCurrentQuestion(questionToEdit || null);
    } else {
      setShowModal("create");
    }
  }

  function closeModal() {
    setShowModal("none");
    setCurrentQuestion(null);
  }

  return (
    <div className="questions">
      <h2>Pathway</h2>
      <Select
        styles={customSelectStyles}
        isLoading={isLoading}
        options={pathSteps}
        value={filterOption}
        onChange={(option) => {
          setFilterOption(option);
        }}
      />

      {!questionsList.length && (
        <div className="text-center mt-3">
          <button
            className="btn questions__button"
            type="button"
            data-toggle="modal"
            data-target="#editModal"
            onClick={() => openModal({})}
          >
            Create +
          </button>
        </div>
      )}

      <div className="questions-tree">
        {tree.map((question, index) => {
          return (
            <Question
              key={index}
              question={question}
              openModal={openModal}
              deleteQuestion={deleteQuestion}
              setPreviousNode={setPreviousNode}
              setQuestionType={setQuestionType}
            />
          );
        })}
      </div>

      <Modal
        className="modal fade"
        show={showModal === "edit"}
        onHide={() => setShowModal("none")}
        id="editModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="editModalLabel"
        aria-hidden="true"
      >
        <Modal.Body>
          <QuestionForm
            currentQuestion={currentQuestion}
            questionsList={options}
            closeModal={closeModal}
            getQuestionsList={getQuestionsList}
            pathName={filterOption?.value}
            questionType={currentQuestion?.questionType}
            isEditing={showModal === "edit"}
          />
        </Modal.Body>
      </Modal>

      <Modal
        className="modal fade"
        show={showModal === "create"}
        onHide={() => setShowModal("none")}
        id="editModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="editModalLabel"
        aria-hidden="true"
      >
        <Modal.Body>
          <QuestionForm
            questionsList={options}
            closeModal={closeModal}
            getQuestionsList={getQuestionsList}
            pathName={filterOption?.value}
            nextJunctionId={nextJunctionId}
            previousNode={previousNode}
            questionType={questionType}
            isEditing={showModal === "edit"}
          />
        </Modal.Body>
      </Modal>
      <Modal
        className="modal fade"
        show={showModal === "error"}
        onHide={() => setShowModal("none")}
        id="errorModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="errorModalLabel"
        aria-hidden="true"
      >
        <Modal.Body>
          <p>{error?.message}</p>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Questions;
