import React, { useEffect, useState } from "react";
import "./style.scss";

const Select = ({
  defaultOption,
  selectOptions,
  handleFilterOption,
  handleSelectOption,
  isFilter,
  disabled,
  type,
}) => {
  const [selectOption, setSelectOption] = useState(
    defaultOption
      ? defaultOption
      : selectOptions && selectOptions.length > 0
      ? selectOptions[0]
      : ""
  );

  function onSelectOptionChange(e) {
    setSelectOption(e.target.value);
    isFilter
      ? handleFilterOption(e.target.value)
      : handleSelectOption(e.target.value);
  }

  useEffect(() => {
    if (defaultOption) {
      setSelectOption(defaultOption);
    } else if (selectOptions && selectOptions.length) {
      setSelectOption(selectOptions[0]);
    }
  }, [defaultOption]);

  return (
    <select
      id="hut-select"
      className="form-control hut-select-item"
      defaultValue={selectOption}
      onChange={(e) => onSelectOptionChange(e)}
      disabled={disabled ? true : false}
    >
      {type === "scenarios"
        ? selectOptions.map(({ name, id }, i) => {
            return (
              <option key={i} value={id}>
                {name}
              </option>
            );
          })
        : selectOptions?.map((id, i) => {
            return (
              <option key={i} value={id}>
                {id}
              </option>
            );
          })}
    </select>
  );
};

export default Select;
