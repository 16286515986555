import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { IoMdCreate, IoMdTrash } from "react-icons/io";
import Modal from "react-bootstrap/Modal";

import "./style.scss";
import { fetchClient } from "../../utils/client";
import HutsEditModal from "./HutsEditModal";
import HutsNavigateModal from "./HutsNavigateModal";
import HutsLawyerCanHelpModal from "./HutsLawyerCanHelpModal";
import HutsDeleteModal from "./HutsDeleteModal";
import HutsChecklist from "./HutsCheckList";
import Select from "react-select";
import { useAsync } from "../../hooks/useAsync";

const customSelectStyles = {
  control: (provided) => ({
    ...provided,
    width: 500,
  }),
  option: (provided, state) => ({
    ...provided,
  }),
};

function Huts() {
  const { data: services, isLoading, run } = useAsync();

  const [showModal, setShowModal] = useState("none");
  const [currentHut, setCurrentHut] = useState("");
  const [filterOption, setFilterOption] = useState(null);
  const [hutsList, setHutsList] = useState([]);
  const [navigateToEdit, setNavigateToEdit] = useState([]);
  const [lawyerCanHelpToEdit, setLawyerCanHelpToEdit] = useState();
  const [activeHutId, setActiveHutId] = useState();
  const [pathSteps, setPathSteps] = useState();
  const [openHutsDeleteModal, setOpenHutsDeleteModal] = useState({
    show: false,
    id: null,
  });

  useEffect(() => {
    Promise.all([fetchClient("huts/related-data"), fetchClient("path-steps")])
      .then(([huts, pathSteps, glossaries]) => {
        // Process Huts, Paths & Glossaries
        if (currentHut._id)
          return setCurrentHut(huts.find((hut) => hut._id === currentHut._id));
        const pathStepsOptions = pathSteps.map((pathStep) => {
          return {
            value: pathStep._id,
            label: pathStep.name,
          };
        });

        setHutsList(huts);
        setFilterOption(pathStepsOptions[0]);
        setPathSteps(pathStepsOptions);
      })
      .catch((err) => console.log(err.mesage));
  }, [currentHut._id]);

  useEffect(() => {
    run(fetchClient("products"));
  }, [run]);

  function handleHutListChanges(hutToChange) {
    let editedHuts = hutsList;
    editedHuts.map((item, i) => {
      if (item._id === hutToChange._id) {
        item.hutSubType = hutToChange.hutSubType;
        item.pathName = hutToChange.pathName;
        item.hutType = hutToChange.hutType;
      }
    });
    return getHutsList();
  }

  function openModal(id) {
    setShowModal("hut");
    id
      ? setCurrentHut(hutsList.find((hut) => hut._id === id))
      : setCurrentHut({});
  }

  function openHavigateModal(id) {
    setShowModal("hut-navigate");
    setNavigateToEdit(id);
  }

  function openLawyerCanHelp(id, hutId) {
    setLawyerCanHelpToEdit(id);
    setActiveHutId(hutId);
    setShowModal("lawyer-can-help");
  }

  function openHutChecklist(hutId) {
    setActiveHutId(hutId);
    setShowModal("hut-checklist");
  }

  function closeModal(id, hutId) {
    if (id) {
      const hutData = hutsList.map((hut) => {
        if (hut._id && hutId) hut.lchId = id;
        return hut;
      });
      setHutsList(hutData);
      setLawyerCanHelpToEdit(id);
    }
    setShowModal("none");
  }

  async function getHutsList() {
    try {
      const hutsWithRelatedData = await fetchClient(`huts/related-data`);
      setHutsList(hutsWithRelatedData);
      if (currentHut._id)
        return setCurrentHut(
          hutsWithRelatedData.find((hut) => hut._id === currentHut._id)
        );
    } catch (err) {
      console.log(err);
    }
  }

  async function deleteHut(id) {
    setOpenHutsDeleteModal({ show: true, id });
  }

  const onHutsDeleteModalClose = () => {
    setOpenHutsDeleteModal({ show: false, id: null });
  };

  const onSuccess = () => {
    setShowModal("none");
    return getHutsList();
  };

  return (
    <div className="huts">
      <div className="header">
        <h1>Huts</h1>
      </div>
      <div className="huts-menu">
        <div className="huts-filter">
          <label>Filter by Step Name: </label>
          {pathSteps ? (
            <Select
              styles={customSelectStyles}
              options={pathSteps}
              value={filterOption}
              onChange={(option) => setFilterOption(option)}
            />
          ) : (
            ""
          )}
        </div>
        <div className="text-center mt-3">
          <button
            className="btn huts__button"
            type="button"
            data-toggle="modal"
            data-target="#editModal"
            onClick={() => openModal()}
          >
            Create +
          </button>
        </div>
      </div>
      <div className="huts">
        {hutsList.map((item, i) => {
          if (item.pathName === filterOption?.value)
            return (
              <div className="huts__item" key={item._id}>
                <div className="huts__item-header">
                  <h4 className="huts__item-heading">{item.hutSubType}</h4>
                  <div className="huts__item-buttons">
                    <button
                      className="bg-success text-white border border-success rounded"
                      type="button"
                      data-toggle="modal"
                      data-target="#editModal"
                      onClick={() => openModal(item._id)}
                    >
                      <IoMdCreate />
                    </button>
                    <div className="huts__item-buttons--delete">
                      <button
                        className="bg-danger text-white border border-danger rounded"
                        onClick={() => deleteHut(item._id)}
                      >
                        <IoMdTrash />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="huts__item-main">
                  <p className="huts__item-info">
                    Entryway Name: <span>{item.entryway.name}</span>
                  </p>
                  <p className="huts__item-info">
                    Hut Type: <span>{item.hutType}</span>
                  </p>
                </div>
                <div className="d-flex justify-content-end align-items-center">
                  <button
                    className="huts__button rounded rounded ml-2"
                    type="button"
                    data-toggle="modal"
                    data-target="#editModal"
                    onClick={() => openHavigateModal(item.navigateId)}
                  >
                    Hut navigate
                    <IoMdCreate />
                  </button>
                  <button
                    className="huts__button rounded rounded ml-2"
                    type="button"
                    data-toggle="modal"
                    data-target="#editModal"
                    onClick={() => openHutChecklist(item._id)}
                  >
                    Hut Checklist
                    <IoMdCreate />
                  </button>
                  <button className="huts__button rounded rounded ml-2">
                    <NavLink
                      className="huts__button"
                      to={{
                        pathname: "/glossary",
                        search: `?hutId=${item._id}`,
                      }}
                    >
                      Glossary
                      <IoMdCreate />
                    </NavLink>
                  </button>
                  <button
                    className="huts__button rounded ml-2"
                    type="button"
                    data-toggle="modal"
                    data-target="#editModal"
                    onClick={() => openLawyerCanHelp(item.lchId, item._id)}
                  >
                    Lawyer Can Help
                    <IoMdCreate />
                  </button>
                </div>
              </div>
            );
        })}
      </div>

      {showModal === "hut" && (
        <Modal
          show={showModal === "hut"}
          className="modal fade"
          id="editModal"
          onHide={() => setShowModal("none")}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="editModalLabel"
          aria-hidden="true"
        >
          <Modal.Body>
            <HutsEditModal
              closeModal={closeModal}
              hut={currentHut}
              handleHutListChanges={handleHutListChanges}
              pathNamesOptions={pathSteps}
            ></HutsEditModal>
          </Modal.Body>
        </Modal>
      )}

      {showModal === "hut-navigate" && (
        <Modal
          show={showModal === "hut-navigate"}
          className="modal fade"
          id="editModal"
          onHide={() => setShowModal("none")}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="editModalLabel"
          aria-hidden="true"
        >
          <Modal.Body>
            <HutsNavigateModal
              closeModal={closeModal}
              navigateId={navigateToEdit}
              services={isLoading ? isLoading : services}
              currentStep={filterOption?.value}
            />
          </Modal.Body>
        </Modal>
      )}

      {showModal === "hut-checklist" && (
        <Modal
          show={showModal === "hut-checklist"}
          className="modal fade"
          id="editModal"
          onHide={() => setShowModal("none")}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="hutChecklistModal"
          aria-hidden="true"
        >
          <Modal.Body>
            <HutsChecklist
              closeModal={closeModal}
              hutId={activeHutId}
              hutsList={isLoading ? isLoading : hutsList}
              currentStep={filterOption?.value}
              successCallback={() => onSuccess()}
            />
          </Modal.Body>
        </Modal>
      )}

      {showModal === "lawyer-can-help" && (
        <Modal
          show={showModal === "lawyer-can-help"}
          className="modal fade"
          id="lawyerCanHelpModal"
          onHide={() => setShowModal("none")}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="showLawyerCanHelpModal"
          aria-hidden="true"
        >
          <Modal.Body>
            <HutsLawyerCanHelpModal
              id={lawyerCanHelpToEdit}
              hutId={activeHutId}
              closeModal={closeModal}
            />
          </Modal.Body>
        </Modal>
      )}

      <HutsDeleteModal
        show={openHutsDeleteModal?.show}
        onHide={onHutsDeleteModalClose}
        handleHutListChanges={handleHutListChanges}
        id={openHutsDeleteModal?.id}
        currentHut={currentHut}
      />
    </div>
  );
}

export default Huts;
