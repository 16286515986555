import React, { useEffect, useState } from "react";

import { IoMdCreate, IoMdTrash } from "react-icons/io";
import "./index.scss";

const LCNEdit = ({ content, blockName, blockTitle, handleChanges }) => {
  const [contentItem, setContentItem] = useState(content || []);
  const [collapse, setCollapse] = useState(false);

  const createNewItem = () => {
    let cntItem = [...content];
    blockName === "value"
      ? cntItem.push({
          listItem: " ",
          listTitle: " ",
          order: contentItem.length ? contentItem.length + 1 : 1
        })
      : cntItem.push({
          listItem: " ",
          order: contentItem.length ? contentItem.length + 1 : 1
        });
    setContentItem([...cntItem]);
  };

  function generateId() {
    return Math.floor(Math.random() * Math.floor(999));
  }

  const deleteItem = index => {
    contentItem.splice(index, 1);
    setContentItem([...contentItem]);
    handleChanges(contentItem, blockName);
  };

  function onItemChange(e, i, title) {
    let cntContent = [...contentItem]
    if(title) {
      cntContent[i].listTitle = e.target.value
      cntContent[i].isTitle = true
    }
    else{
      cntContent[i].listItem = e.target.value
    }
    setContentItem([...cntContent]);
    handleChanges(cntContent, blockName);
  }

  const titleBlock = (item, i) => {
    if (item.listTitle || item.isTitle)
      return (
        <>
          <p className="mt-2 mb-1 ml-1">Title:</p>
          <input
            className="form-control"
            type="text"
            defaultValue={item.listTitle}
            onChange={e => onItemChange(e, i, "title")}
          />
        </>
      );
  };

  const TextAreaInput = (item,i) => {
    return (
      <textarea 
        defaultValue={item.listItem}
        rows="4"
        className="form-control"
        onChange={e => onItemChange(e, i)}
      />
    )
 }


  useEffect(() => {
    setContentItem(content)
  }, [content])

  return (
    <div className="huts-lch">
      <div className="d-flex justify-content-between align-items-baseline mt-5">
        <p className="h6 font-weight-bold">{blockTitle}</p>
        <button
          className="bg-success text-white border border-success rounded"
          type="button"
          data-toggle="collapse"
          data-target={"#collapse" + { blockName }}
          aria-expanded="false"
          aria-controls={"collapse" + { blockName }}
          onClick={() => setCollapse(!collapse)}
        >
          <IoMdCreate />
        </button>
      </div>
      {collapse ? (
        <>
          <div className="mt-2 d-flex justify-content-end">
            <button
              className="btn huts-lch__button"
              onClick={() => createNewItem()}
            >
              New Item
            </button>
          </div>
          
          {contentItem && contentItem.map((item, i) => (
            <div
              key={item._id ? item._id : i}
              className="border p-2 my-1 rounded"
            >
              <div className="d-flex justify-content-between mt-2  align-items-baseline">
                <p className="font-weight-bold">#{i + 1}. Item</p>{" "}
                <button
                  className="bg-danger text-white border border-danger rounded"
                  onClick={() => deleteItem(i)}
                >
                  <IoMdTrash />
                </button>
              </div>
              <div>{titleBlock(item, i)}</div>
              <p className="mt-2 mb-1 ml-1">Item info:</p>              
              <div>{TextAreaInput(item, i)}</div>
            </div>
          ))}
        </>
      ) : null}
    </div>
  );
};

export default LCNEdit;
