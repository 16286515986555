import React, { useEffect, useState } from "react";
import client from "../../../utils/client";
import "./style.scss";

import Select from "react-select";

function HutsEditModal({
  hut: currentHut,
  closeModal,
  handleHutListChanges,
  pathNamesOptions,
}) {
  const [hutType, setHutType] = useState(currentHut ? currentHut.hutType : "");
  const [hutSubType, setHutSubType] = useState(
    currentHut ? currentHut.hutSubType : ""
  );
  const [pathName, setPathName] = useState(
    currentHut._id ? currentHut.pathName : pathNamesOptions[0]
  );

  function onHutTypeChange(e) {
    setHutType(e.target.value);
  }

  function onHutSubTypeChange(e) {
    setHutSubType(e.target.value);
  }

  function handlePathNameChange(selectedItem) {
    setPathName(selectedItem);
  }

  function saveHutInformation(e) {
    e.preventDefault();
    if (
      pathName !== currentHut.pathName ||
      hutSubType !== currentHut.hutSubType ||
      hutType !== currentHut.hutType
    ) {
      currentHut._id ? editHutInformation() : createNewHut();
    }
    closeModal();
  }

  async function editHutInformation() {
    let hutContent = {
      pathName: pathName.value,
      hutType: hutType,
      hutSubType: hutSubType,
    };

    try {
      await client.put(`huts/${currentHut._id}`, hutContent);
      hutContent._id = currentHut._id;
      await handleHutListChanges(hutContent);
    } catch (err) {
      console.error(err);
    }
  }

  async function createNewHut() {
    let hutContent = {
      pathName: pathName.value,
      hutType: hutType,
      hutSubType: hutSubType,
    };

    try {
      await client.post(`huts`, hutContent);
      handleHutListChanges(hutContent);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    if (currentHut.pathName) {
      const pathName = pathNamesOptions.find(
        (path) => path.value === currentHut.pathName
      );
      setPathName(pathName);
    } else if (pathNamesOptions?.length) {
      setPathName(pathNamesOptions[0]);
    }
  }, [currentHut, pathNamesOptions]);

  return (
    <div className="huts-modal">
      <div>
        <h2>
          {currentHut._id ? `Hut Edit(${currentHut.hutSubType})` : "Hut Create"}
          :
        </h2>
      </div>
      <div>
        <label htmlFor="hut-path-name-select">Entryway Name: </label>
        <Select
          onChange={handlePathNameChange}
          options={pathNamesOptions}
          value={pathName}
        />
      </div>
      <div className="huts-modal__item">
        <p className="huts-modal__item-heading">Type:</p>
        <input
          className="form-control"
          type="text"
          defaultValue={hutType}
          onChange={(e) => onHutTypeChange(e)}
        ></input>
      </div>
      <div className="huts-modal__item">
        <p className="huts-modal__item-heading">SubType:</p>
        <input
          className="form-control"
          type="text"
          defaultValue={hutSubType}
          onChange={(e) => onHutSubTypeChange(e)}
        ></input>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="modal-footer__button--close"
          data-dismiss="modal"
          onClick={() => closeModal()}
        >
          Cancel
        </button>
        <input
          type="submit"
          className="modal-footer__button--save"
          value="Save"
          onClick={(e) => saveHutInformation(e)}
        />
      </div>
    </div>
  );
}

export default HutsEditModal;
