import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "./App.css";
import "./styles/style.scss";

import Home from "./components/Home";
import Sidebar from "./components/Sidebar";
import Questions from "./components/Questions";
import Context from "./context/context";
import Huts from "./components/Huts";
import PathSteps from "./components/PathSteps";
import Services from "./components/Services";
import Glossary from "./components/Glossary";
import FAQ from "./components/FAQ";
import Login from "./components/Login";
import { ProvideAuth } from "./context/use-auth";

import { ErrorBoundary } from "react-error-boundary";

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ width: "100%", height: "100vh" }}
    >
      <div role="alert">
        <p>Something went wrong:</p>
        <pre className="text-danger">{error.message}</pre>
        <button className="btn btn-dark" onClick={resetErrorBoundary}>
          Try again
        </button>
      </div>
    </div>
  );
}

function App() {
  const accessToken = localStorage.getItem("accessToken");

  return (
    <Context.Provider>
      <ProvideAuth>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Router>
            <div className="App">
              {accessToken && <Sidebar />}
              <div className={accessToken ? "main" : "w-100"}>
                <Switch>
                  <Route exact path="/login">
                    <Login />
                  </Route>
                  {accessToken ? (
                    <>
                      <Route exact path="/">
                        <Home />
                      </Route>
                      <Route path="/questions">
                        <Questions />
                      </Route>
                      <Route path="/huts">
                        <Huts />
                      </Route>
                      <Route path="/steps">
                        <PathSteps />
                      </Route>
                      <Route path="/services">
                        <Services />
                      </Route>
                      <Route path="/glossary">
                        <Glossary />
                      </Route>
                      <Route path="/faq">
                        <FAQ />
                      </Route>
                    </>
                  ) : (
                    <Redirect to="/login" />
                  )}
                </Switch>
              </div>
            </div>
          </Router>
        </ErrorBoundary>
      </ProvideAuth>
    </Context.Provider>
  );
}

export default App;
