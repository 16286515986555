import * as React from "react";
import "./style.scss";
import Table from "./Table";
import GlossaryModal from "./GlossaryModal";
import { fetchClient } from "./../../utils/client";
import { IoMdCreate, IoMdTrash } from "react-icons/io";
import { useAsync } from "../../hooks/useAsync";
import Select from "react-select";
import Loader from "../../utils/loader";

const fetchHuts = () => fetchClient(`huts?$sort={"hutSubType": 1}`);
const fetchGlossaries = (hutId) =>
  fetchClient(hutId ? `glossaries?huts=${hutId}` : "glossaries");

const trimStr = (count, str) => `${str.slice(0, count)}...`;

const Glossary = (props) => {
  const searchParams = new URLSearchParams(window.location.search);
  const hutId = searchParams.get("hutId");

  const {
    data: glossaries,
    status: glossariesQueryStatus,
    run: runGlossariesQuery,
  } = useAsync();
  const { data: huts, error, status, run } = useAsync();
  const [glossary, setGlossary] = React.useState({});
  const [hutOptions, setHutOptions] = React.useState([]);
  const [modalShow, setModalShow] = React.useState("none");
  const [selectedHut, setSelectedHut] = React.useState(null);

  // Derived state
  const isCreateModalVisible = modalShow === "create";
  const isEditModalVisible = modalShow === "edit";

  const columns = React.useMemo(() => {
    return [
      {
        Header: "Term",
        accessor: "term",
        filter: "fuzzyText",
      },
      {
        Header: "Definition",
        accessor: "definition",
        disableFilters: true,
        Cell: ({
          cell: {
            row: { original: item },
          },
        }) => (
          <p>
            {item.definition.length > 50
              ? trimStr(47, item.definition)
              : item.definition}
          </p>
        ),
      },
      {
        Header: "Actions",
        Cell: ({
          cell: {
            row: { original: item },
          },
        }) => {
          return (
            <>
              <button
                className="bg-success text-white border border-success rounded"
                type="button"
                onClick={() => {
                  setGlossary(item);
                  setModalShow("edit");
                }}
              >
                <IoMdCreate />
              </button>
              <button
                className="bg-danger text-white border border-danger rounded ml-1"
                onClick={() => removeGlossary(item._id)}
              >
                <IoMdTrash />
              </button>
            </>
          );
        },
      },
    ];
  }, []);

  const removeGlossary = (glossaryId) => {
    fetchClient(`glossaries/${glossaryId}`, {
      method: "DELETE",
    })
      .then((res) => window.location.reload())
      .catch((err) => {
        throw new Error(err.message);
      });
  };

  const viewGlossaryData = (obj) => {
    setGlossary(obj);
    setModalShow("edit");
  };

  React.useEffect(() => {
    if (hutId) {
      const selectedHutOption = hutOptions.find((hut) => hut.value === hutId);
      setSelectedHut(selectedHutOption);
    }
  }, [hutId, hutOptions]);

  React.useEffect(() => {
    run(fetchHuts());
    runGlossariesQuery(fetchGlossaries());
  }, [run, runGlossariesQuery]);

  React.useEffect(() => {
    if (huts?.length) {
      const hutOptions = huts?.map((hut) => ({
        value: hut._id,
        label: `${hut.hutSubType} (${hut.pathName.name})`,
      }));

      setHutOptions(hutOptions);
    }
  }, [huts]);

  React.useEffect(() => {
    if (selectedHut) {
      runGlossariesQuery(fetchGlossaries(selectedHut.value));
    }
  }, [selectedHut, runGlossariesQuery]);

  return (
    <>
      <div className="mb-2">
        <div className="glossary d-flex justify-content-between my-2">
          <h2 className="header">Glossaries</h2>
          <button
            className="btn glossary__button"
            type="button"
            onClick={() => setModalShow("create")}
          >
            Create +
          </button>
        </div>
        <p>Filter by Hut:</p>
        <Select
          isLoading={status === "pending"}
          options={hutOptions}
          value={selectedHut}
          onChange={(value, { action }) => {
            if (action !== "clear") {
              setSelectedHut((prev) => ({ ...prev, ...value }));
            } else {
              setSelectedHut({ value: "", label: "Select..." });
            }
          }}
          isClearable
        />
      </div>
      {glossariesQueryStatus === "pending" ||
      glossariesQueryStatus === "idle" ? (
        <Loader />
      ) : (
        <Table
          columns={columns}
          data={glossaries}
          openGlossaryData={viewGlossaryData}
        />
      )}
      {isEditModalVisible && (
        <GlossaryModal
          type={"edit"}
          show={modalShow === "edit"}
          onHide={() => setModalShow("none")}
          data={glossary}
          huts={hutOptions}
        />
      )}
      {isCreateModalVisible && (
        <GlossaryModal
          type={"create"}
          show={modalShow === "create"}
          onHide={() => setModalShow("none")}
          huts={hutOptions}
        />
      )}
    </>
  );
};
export default Glossary;
