import React, { useEffect, useState } from "react";
import { fetchClient } from "../../../utils/client";

import { IoMdCreate, IoMdTrash, IoMdClose } from "react-icons/io";
import Select from "react-select";
import MDEditor from "@uiw/react-md-editor";

import "./index.scss";

const NavigateEntryModel = ({ updatedAt, createdAt, __v, hut, ...data }) => ({
  ...data,
});

const customSelectStyles = {
  container: (provided) => ({
    ...provided,
    width: "100%",
  }),
  option: (provided, state) => ({
    ...provided,
  }),
};

function HutsNavigateModal({ navigateId, closeModal, services, currentStep }) {
  const [navigateEntry, setNavigateEntry] = useState(null);
  const [navigateEntryVideoLink, setNavigateEntryVideoLink] = useState(null);
  const [navigateEntryVideoTitle, setNavigateEntryVideoTitle] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    getNavigateEntry();
  }, []);

  async function getNavigateEntry() {
    try {
      const navigateEntryResponse = await fetchClient(
        `navigate-entries/${navigateId}`
      );
      const navigateEntry = NavigateEntryModel(navigateEntryResponse);

      setNavigateEntry(navigateEntry);
      setNavigateEntryVideoLink(navigateEntry.videoLink);
      setNavigateEntryVideoTitle(navigateEntry.videoTitle);
    } catch (err) {
      console.log(err);
    }
  }

  // TODO: Refactor all of this after MVP
  async function editNavigateEntryRequest() {
    try {
      let isError = false;
      if (navigateEntry) {
        var pattern =
          /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;
        if (!pattern.test(navigateEntry.videoLink)) {
          isError = true;
        }

        navigateEntry.topics.map((tps) => {
          if (!tps.title && !tps.details) {
            isError = true;
            return alert(
              "Blank topic is not allowed, please add more details or remove it"
            );
          } else if (!tps.details) {
            isError = true;
            return alert("Details are not allowed to be empty is not allowed");
          } else if (!tps.title) {
            isError = true;
            return alert("Title are not allowed to be empty is not allowed");
          } else {
            isError = false;
          }

          tps.additionals.map((tps) => {
            if (!tps.title && !tps.description) {
              isError = true;
              return alert(
                "Topic additional details are blank is not allowed, please add more details or remove it"
              );
            } else if (!tps.description) {
              isError = true;
              return alert(
                "Additional description are not allowed to be empty is not allowed"
              );
            } else if (!tps.title) {
              isError = true;
              return alert(
                "Additional Title are not allowed to be empty is not allowed"
              );
            } else {
              isError = false;
            }
          });
        });
      }
      if (!isError) {
        await fetchClient(`navigate-entries/${navigateId}`, {
          data: navigateEntry,
          method: "PUT",
        });
        closeModal();
      }
    } catch (err) {
      console.error(err);
    }
  }

  function onNavigateEntryVideoInfoChange(e, field) {
    field === "link"
      ? (navigateEntry.videoLink = e.target.value)
      : (navigateEntry.videoTitle = e.target.value);
    setNavigateEntry({ ...navigateEntry });
  }

  const createNewTopic = () => {
    navigateEntry.topics.push({
      title: "",
      details: "",
      additionals: [],
      products: [],
    });
    setNavigateEntry({ ...navigateEntry });
  };

  const onAdditionalItemChange = (value, i, item, paragraphIndex) => {
    paragraphIndex !== undefined
      ? (navigateEntry.topics[i].additionals[paragraphIndex][item] = value)
      : (navigateEntry.topics[i][item] = value);
    setNavigateEntry({ ...navigateEntry });
  };

  function saveNavigateEntry(e) {
    e.preventDefault();
    editNavigateEntryRequest();
  }

  function deleteTopic(topicId) {
    const removeIndex = navigateEntry.topics
      .map(function (item) {
        return item._id;
      })
      .indexOf(topicId);
    navigateEntry.topics.splice(removeIndex, 1);
    setNavigateEntry({ ...navigateEntry });
  }

  function deleteService(topic, product) {
    navigateEntry.topics[topic].products.splice(product, 1);
    setNavigateEntry({ ...navigateEntry });
  }

  function handleSelectOption(item) {
    setSelectedItem(item);
  }

  function addService(topicNum) {
    const serviceToAdd = services.find(
      (service) => service._id === selectedItem.value
    );
    navigateEntry.topics[topicNum].products.push({ id: serviceToAdd._id });
    setNavigateEntry({ ...navigateEntry });
  }

  const servicesBlock = (products, topicNum) => {
    let serviceInfo = (id) => {
      const currentService = services.find((service) => service._id === id);
      return currentService?.productName ?? "";
    };

    const filteredServices = services.filter(
      (service) => service?.hut?.pathName._id === currentStep
    );
    const servicesOptions = filteredServices.map((item) => ({
      label: `${item.productName} (${item.hut?.pathName?.name})`,
      value: item._id,
    }));

    return (
      <>
        <p className="font-weight-bold">Services:</p>
        <div className="d-flex justify-content-between my-4">
          <Select
            styles={customSelectStyles}
            onChange={handleSelectOption}
            options={servicesOptions}
          />
          <button
            className="huts__button rounded ml-2"
            onClick={() => addService(topicNum)}
          >
            +
          </button>
        </div>
        {products.length !== 0 && services
          ? products.map((product, k) => (
              <div className="d-flex justify-content-between my-2" key={k}>
                <p className="ml-1 my-0">> {serviceInfo(product.id)}</p>
                <button
                  className="bg-danger text-white border border-danger rounded ml-2"
                  onClick={() => deleteService(topicNum, k)}
                >
                  <IoMdTrash />
                </button>
              </div>
            ))
          : "0 connected services"}
      </>
    );
  };

  return (
    <div className="huts-modal">
      {navigateEntry ? (
        <div>
          <h2>Hut Navigate Edit:</h2>
          <div>
            <p className="huts-modal__item-heading">Video Title:</p>
            <div className="input-group">
              <input
                className="form-control"
                type="text"
                defaultValue={navigateEntryVideoTitle}
                onChange={(e) => onNavigateEntryVideoInfoChange(e, "title")}
              />
              <div className="input-group-append">
                <span className="input-group-text">
                  <IoMdClose
                    onClick={() => {
                      setNavigateEntry((prev) => ({
                        ...prev,
                        videoTitle: null,
                      }));
                      setNavigateEntryVideoTitle(null);
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
          <div>
            <p className="huts-modal__item-heading">Video Link:</p>
            <div className="input-group">
              <input
                className="form-control"
                type="text"
                defaultValue={navigateEntryVideoLink}
                onChange={(e) => onNavigateEntryVideoInfoChange(e, "link")}
              />
              <div className="input-group-append">
                <span className="input-group-text">
                  <IoMdClose
                    onClick={() => {
                      setNavigateEntry((prev) => ({
                        ...prev,
                        videoLink: null,
                      }));
                      setNavigateEntryVideoLink(null);
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
          <div>
            <div className="d-flex justify-content-between align-items-center mt-2 mb-2">
              <p className="huts-modal__item-heading">Topics:</p>
              <button
                type="button"
                className="btn huts-modal__button"
                onClick={createNewTopic}
              >
                New Topic
              </button>
            </div>
            {navigateEntry.topics.map((topic, i) => (
              <div key={i}>
                <div className="d-flex justify-content-between mt-2">
                  <p className="huts-modal__item-heading">{topic.title}</p>
                  <div>
                    <button
                      className="bg-success text-white border border-success rounded mr-1"
                      type="button"
                      data-toggle="collapse"
                      data-target={"#collapse" + i}
                      aria-expanded="false"
                      aria-controls={"collapse" + i}
                    >
                      <IoMdCreate />
                    </button>
                    <button
                      className="bg-danger text-white border border-danger rounded ml-2"
                      onClick={() =>
                        deleteTopic(topic._id ? topic._id : "newTopic")
                      }
                    >
                      <IoMdTrash />
                    </button>
                  </div>
                </div>
                <div className="collapse" id={"collapse" + i}>
                  <div>
                    <p className="mt-1 mb-1 font-weight-bold">Title:</p>
                    <input
                      className="form-control mt-1"
                      type="text"
                      defaultValue={topic.title}
                      onChange={(e) =>
                        onAdditionalItemChange(e.target.value, i, "title")
                      }
                    />
                    <p className="mt-1 mb-1 font-weight-bold">Details:</p>
                    <MDEditor
                      value={topic.details}
                      onChange={(value) =>
                        onAdditionalItemChange(value, i, "details")
                      }
                      preview={"edit"}
                    />
                  </div>
                  <div className="border-top my-4 pt-2">
                    {servicesBlock(topic.products, i)}
                  </div>
                </div>
              </div>
            ))}
            <div className="modal-footer mt-1">
              <button
                type="button"
                className="modal-footer__button--close"
                data-dismiss="modal"
                onClick={() => closeModal()}
              >
                Cancel
              </button>
              <input
                type="submit"
                className="modal-footer__button--save"
                value="Save"
                onClick={(e) => saveNavigateEntry(e)}
              />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default HutsNavigateModal;
