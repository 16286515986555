import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import client from "../../../utils/client";
import "./style.scss";

function PathStepModal({
  pathStep,
  closeModal,
  handlePathStepsChanges,
  stepIds,
}) {
  const [currentPathStep] = useState(pathStep);
  const [stepName, setStepName] = useState(
    currentPathStep ? currentPathStep.name : ""
  );
  const [stepDescription, setStepDescription] = useState(
    currentPathStep ? currentPathStep.description : ""
  );
  const [stepHint, setStepHint] = useState(
    currentPathStep ? currentPathStep.hint : ""
  );
  const [stepTitle, setStepTitle] = useState(
    currentPathStep ? currentPathStep.title : ""
  );
  const [stepActive, setStepActive] = useState(false);
  const [stepNumStep, setStepNumStep] = useState(
    currentPathStep ? currentPathStep.step : 0
  );
  const [upStep, setUpStep] = useState();

  const hutContent = {
    name: stepName,
    description: stepDescription,
    hint: stepHint,
    step: stepNumStep,
    title: stepTitle,
    active: stepActive,
  };

  useEffect(() => {
    currentPathStep.active ? setStepActive(true) : setStepActive(false);
    setUpStep(stepNumStep);
  }, []);

  function onStepNameChange(e) {
    setStepName(e.target.value);
  }

  function onStepActiveChange(e) {
    stepActive ? setStepActive(false) : setStepActive(true);
  }

  function onStepDescriptionChange(e) {
    setStepDescription(e.target.value);
  }

  function onStepHintChange(e) {
    setStepHint(e.target.value);
  }

  function onStepTitleChange(e) {
    setStepTitle(e.target.value);
  }

  function onStepNumStepChange(e) {
    setStepNumStep(e.target.value);
  }

  function savePathStepInformation(e) {
    e.preventDefault();
    if (
      stepName !== currentPathStep.stepName ||
      stepDescription !== currentPathStep.stepDescription ||
      stepHint !== currentPathStep.stepHint ||
      stepTitle !== currentPathStep.stepTitle ||
      stepNumStep !== currentPathStep.stepNumStep
    ) {
      currentPathStep._id ? editPathStep() : createNewPathStep();
    }
    closeModal();
  }

  async function editPathStep() {
    try {
      await client.put(`path-steps/${currentPathStep._id}`, hutContent);        
      handlePathStepsChanges();
    } catch (err) {
      console.error(err);
    }
  }

  async function createNewPathStep(hutData) {
    try {
      await client.post(`path-steps`, hutData);     
      handlePathStepsChanges();
    } catch (err) {
      console.error(err);
    }
  }

  //Form submission
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    data.hint="empty"
    if (currentPathStep._id) {
      if (
        upStep != data.step &&
        stepIds.includes(parseInt(data.step)) === true
      ) {
        alert("Step # already exists");
      } else {
        editPathStep();
        closeModal();
      }
    } else {
      if (stepIds.includes(parseInt(data.step)) === false) {
        createNewPathStep(data);
        closeModal();
      } else {
        alert("Entryway Id already exists");
      }
    }
  };

  return (
    <div className="path-steps-modal">
      <div>
        <h2>
          {currentPathStep._id
            ? `Edit a Step(${currentPathStep.name})`
            : "Create a Step"}
          :
        </h2>
      </div>
      <div className="path-steps-modal__item" style={{ display: "none" }}>
        <p className="path-steps-modal__item-heading">
          ID: <span>{currentPathStep._id ? currentPathStep._id : ""}</span>
        </p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="path-steps-modal__item d-flex align-items-center">
          <p className="path-steps-modal__item-heading">Active:</p>
          <input
            className="form-control path-steps-modal__item-checkbox"
            type="checkbox"
            checked={stepActive}
            {...register("active")}
            onChange={(e) => onStepActiveChange(e)}
          ></input>
        </div>
        <div className="path-steps-modal__item">
          <p className="path-steps-modal__item-heading">Step #*:</p>
          <input
            className="form-control"
            type="number"
            defaultValue={stepNumStep}
            max="100"
            {...register("step", { required: true, min: 0, max: 100 })}
            onChange={(e) => onStepNumStepChange(e)}
          ></input>
          <span className="error">{errors.step && "Step # is required"}</span>
        </div>
        <div className="path-steps-modal__item">
          <p className="path-steps-modal__item-heading">Step Name*:</p>
          <input
            className="form-control"
            type="text"
            defaultValue={stepName}
            {...register("name", { required: true, maxLength: 250 })}
            onChange={(e) => onStepNameChange(e)}
          ></input>
          <span className="error">
            {errors.name &&
              errors.name.type == "required" &&
              "Name is required"}
            {errors.name &&
              errors.name.type == "maxLength" &&
              "Name cannot be longer than 250"}
          </span>
        </div>
        <div className="path-steps-modal__item">
          <p className="path-steps-modal__item-heading">Description A*:</p>
          <textarea
            className="form-control"
            type="text"
            defaultValue={stepTitle}
            {...register("title", { required: true, maxLength: 250 })}
            onChange={(e) => onStepTitleChange(e)}
          ></textarea>
          <span className="error">
            {errors.title &&
              errors.title.type == "required" &&
              "Description A is required"}
            {errors.title &&
              errors.title.type == "maxLength" &&
              "Description A cannot be longer than 250"}
          </span>
        </div>
        <div className="path-steps-modal__item">
          <p className="path-steps-modal__item-heading">Description B*:</p>
          <textarea
            className="form-control"
            type="text"
            defaultValue={stepDescription}
            {...register("description", { required: true, maxLength: 500 })}
            onChange={(e) => onStepDescriptionChange(e)}
          ></textarea>
          <span className="error">
            {errors.description &&
              errors.description.type == "required" &&
              "Description B is required"}
            {errors.description &&
              errors.description.type == "maxLength" &&
              "Description B cannot be longer than 500"}
          </span>
        </div>
        <div className="modal-footer path-steps-modal__item">
          <button
            type="button"
            className="btn btn-danger"
            data-dismiss="modal"
            onClick={() => closeModal()}
          >
            Cancel
          </button>
          <input type="submit" className="btn btn-success" value="Save" />
        </div>
      </form>
    </div>
  );
}

export default PathStepModal;
