import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import client from "./../../utils/client"
import "./style.scss";

const Login = (props) => {
  const [errorMessage, setErrorMessage] = useState([]);
  const [password, setPassword] = useState("");

  function onPasswordChange(e) {
    setPassword(e.target.value);
  }

  async function saveForm(e) {
    e.preventDefault();   
    try {
      if(!password){
        setErrorMessage([
          "Please enter a valid password"
        ]);
      }
      
      const result = await client.post(`authentication/login`, {
        password,
      });     
      if(result.data.success){
        localStorage.setItem("accessToken", result.data.token)
        window.location.href = "/";
      }      
      else{        
        setErrorMessage([
          result.data.error
        ]);    
      }
    } catch (err) {
      console.error(err);
    }
  
  }

  return (
      <div className="wrapper">
        <h1 className="text-center">Legalucy admin</h1> 
        <div className="mt-5">
          <form className="login-form" onSubmit={e => saveForm(e)}>
          {errorMessage.length != 0 && (
            <div className="alert alert-danger" role="alert">
              {errorMessage}
            </div>
          )}        
          <div className="form-group">
            <label htmlFor="question-details">Password</label>
            <input
              className="form-control"
              type="password"
              onChange={onPasswordChange}
            />
          </div>
          <div className="modal-footer text-center">
            <input
              type="submit"
              className="modal-footer__button--save"
              value="Submit"
            />
          </div>                 
        </form>
       </div>
    </div>
  );
}

export default withRouter(Login);
