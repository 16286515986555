import * as React from "react";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import client from "./../../../utils/client";
import "./style.scss";
import clsx from "clsx";

import Select from "react-select";

const objectIdRegex = /^[0-9a-fA-F]{24}$/;

// toInt :: String -> Number
const toInt = (a) => parseInt(a, 10);

const searchNode = (where, currentNode) => {
  const node = where.find((node) => node.value === currentNode);
  return node;
};

const QuestionForm = ({
  currentQuestion,
  questionsList,
  closeModal,
  getQuestionsList,
  pathName,
  nextJunctionId,
  previousNode,
  questionType,
  isEditing,
}) => {
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    setError,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      currentNode: currentQuestion
        ? currentQuestion.currentNode
        : nextJunctionId,
      details: currentQuestion ? currentQuestion.details : "",
      nextQuestionIfYes: currentQuestion
        ? currentQuestion.yes?.nextNode !== 222222
          ? searchNode(questionsList[0].options, currentQuestion.yes.nextNode)
          : searchNode(questionsList[1].options, currentQuestion.yes.hut)
        : null,
      nextQuestionIfNo: currentQuestion
        ? currentQuestion.no?.nextNode !== 222222
          ? searchNode(questionsList[0].options, currentQuestion.no.nextNode)
          : searchNode(questionsList[1].options, currentQuestion.no.hut)
        : null,
      pathName: currentQuestion ? currentQuestion.pathName : pathName,
      previousQuestion: currentQuestion
        ? currentQuestion.previousNode
        : previousNode,
      questionType: questionType,
      goToEntryway: currentQuestion?.goToEntryway
        ? currentQuestion.goToEntryway
        : false,
      idkTitle: currentQuestion?.idk ? currentQuestion.idk.videoTitle : null,
      idkURL: currentQuestion?.idk ? currentQuestion.idk.URL : null,
    },
  });

  const watchGoToEntryway = watch("goToEntryway");

  async function saveForm(formData) {
    let errors = false;
    let {
      details,
      idkTitle,
      idkURL,
      currentNode,
      previousQuestion: previousNode,
      nextQuestionIfYes: nextYesNode,
      nextQuestionIfNo: nextNoNode,
      goToEntryway,
    } = formData;

    if (goToEntryway) {
      nextYesNode = null;
      nextNoNode = null;
    }

    // These field are nullable
    if (nextYesNode && nextNoNode) {
      if (
        objectIdRegex.test(nextYesNode.value) &&
        objectIdRegex.test(nextNoNode.value)
      ) {
        if (nextYesNode.value === nextNoNode.value) {
          setError("nextQuestionIfYes", {
            type: "manual",
            message: "You must select different Huts",
          });
          setError("nextQuestionIfNo", {
            type: "manual",
            message: "You must select different Huts",
          });
          errors = true;
        }
      } else {
        if (toInt(nextYesNode.value) === toInt(nextNoNode.value)) {
          setError("nextQuestionIfYes", {
            type: "manual",
            message: "You must select different questions",
          });
          setError("nextQuestionIfNo", {
            type: "manual",
            message: "You must select different questions",
          });
          errors = true;
        }
      }
    }

    if (errors) return;
    const data = {
      details,
      questionType,
      goToEntryway: Boolean(goToEntryway),
    };

    if (currentQuestion) {
      data.currentNode = toInt(currentNode) || currentQuestion.currentNode;
    } else {
      // Will never be undefined or null because we set it
      data.currentNode = toInt(currentNode);
    }

    if (!currentQuestion) data.pathName = pathName;

    if (!currentQuestion) {
      data.previousNode = previousNode ? toInt(previousNode) : null;
    }

    if (currentQuestion) {
      // If we selected a Hut to go to
      if (objectIdRegex.test(nextYesNode?.value)) {
        data.yes = {
          nextNode: 222222,
          URL: "/dashboard/entryway/pathway/hut/",
          hut: nextYesNode.value,
        };
      } else {
        data.yes = { nextNode: nextYesNode ? toInt(nextYesNode.value) : null };
      }
    } else {
      if (objectIdRegex.test(nextYesNode?.value)) {
        data.yes = {
          nextNode: 222222,
          URL: "/dashboard/entryway/pathway/hut/",
          hut: nextYesNode.value,
        };
      } else {
        data.yes = { nextNode: nextYesNode ? toInt(nextYesNode.value) : null };
      }
    }

    if (currentQuestion) {
      if (objectIdRegex.test(nextNoNode?.value)) {
        data.no = {
          nextNode: 222222,
          URL: "/dashboard/entryway/pathway/hut/",
          hut: nextNoNode.value,
        };
      } else {
        data.no = { nextNode: nextNoNode ? toInt(nextNoNode.value) : null };
      }
    } else {
      if (objectIdRegex.test(nextNoNode?.value)) {
        data.no = {
          nextNode: 222222,
          URL: "/dashboard/entryway/pathway/hut/",
          hut: nextNoNode.value,
        };
      } else {
        data.no = { nextNode: nextNoNode ? toInt(nextNoNode.value) : null };
      }
    }

    data.idk = { videoTitle: idkTitle, URL: idkURL };

    if (currentQuestion) {
      // Delete questionType because it's not need to update
      delete data.questionType;
      try {
        await client.put(`questions/${currentQuestion._id}`, {
          ...data,
        });
        closeModal();
      } catch (err) {
        console.error(err);
      }
    } else {
      try {
        await client.post(`questions`, {
          ...data,
        });
        closeModal();
      } catch (err) {
        console.error(err);
      }
    }

    getQuestionsList(pathName);
  }

  return (
    <form className="question-form" onSubmit={handleSubmit(saveForm)}>
      <input {...register("currentNode")} type="hidden" />
      <input {...register("previousQuestion")} type="hidden" />
      <h2>
        {currentQuestion
          ? `Edit Pathway (ID: ${
              currentQuestion ? currentQuestion.currentNode : ""
            })`
          : `Create Pathway (Junction #${nextJunctionId})`}
      </h2>
      <div className="form-group">
        <label htmlFor="question-details">Details</label>
        <textarea
          id="question-details"
          className="form-control"
          {...register("details", {
            required: true,
            minLength: 3,
            maxLength: 500,
          })}
        />
        {errors.details?.type === "required" && (
          <label className="text-danger">Details are required</label>
        )}
        {errors.details?.type === "minLength" && (
          <label className="text-danger">
            Should be, at least 3 characters
          </label>
        )}
        {errors.details?.type === "maxLength" && (
          <label className="text-danger">
            Should be, at most 500 characters
          </label>
        )}
      </div>
      <div className="form-group">
        <label className="d-block" htmlFor="goToEntryway">
          Go to Entryway
        </label>
        <input type="checkbox" {...register("goToEntryway")} />
      </div>
      {isEditing && (
        <div className="form-group">
          <label htmlFor="question-details">Next Yes pathway</label>
          <Controller
            name="nextQuestionIfYes"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <Select
                  options={questionsList}
                  isClearable
                  value={value}
                  onChange={onChange}
                  isDisabled={watchGoToEntryway}
                />
              );
            }}
          />
          {errors.nextQuestionIfYes && (
            <label className="text-danger">
              {errors.nextQuestionIfYes.message}
            </label>
          )}
        </div>
      )}
      {isEditing && (
        <div className="form-group">
          <label htmlFor="next-no-select">Next No pathway</label>
          <Controller
            name="nextQuestionIfNo"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <Select
                  options={questionsList}
                  isClearable
                  value={value}
                  onChange={onChange}
                  isDisabled={watchGoToEntryway}
                />
              );
            }}
          />
          {errors.nextQuestionIfNo && (
            <label className="text-danger">
              {errors.nextQuestionIfNo.message}
            </label>
          )}
        </div>
      )}
      <div className="form-group">
        <label htmlFor="question-idk-title">I don't know video title</label>
        <div className="input-group">
          <input
            id="question-idk-title"
            className={clsx({
              "form-control": true,
              "is-invalid": errors.idkTitle,
            })}
            type="text"
            {...register("idkTitle", {
              validate: {
                noWhitespace: (v) =>
                  v?.trim().length !== 0 || "Only whitespaces not allowed",
              },
            })}
          />
          <div className="input-group-append">
            <span
              style={{
                cursor: "pointer",
              }}
              className="input-group-text"
              aria-hidden="true"
              onClick={() => setValue("idkTitle", null)}
            >
              &times;
            </span>
          </div>
          {errors.idkTitle && (
            <label className="invalid-feedback">
              {errors.idkTitle.message}
            </label>
          )}
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="question-idk-url">I don't know URL</label>
        <div className="input-group">
          <input
            id="question-idk-url"
            className={clsx({
              "form-control": true,
              "is-invalid": errors.idkURL,
            })}
            type="text"
            {...register("idkURL", {
              validate: {
                noWhitespace: (v) =>
                  v?.trim().length !== 0 || "Only whitespaces not allowed",
              },
            })}
          />
          <div className="input-group-append">
            <span
              style={{
                cursor: "pointer",
              }}
              className="input-group-text"
              aria-hidden="true"
              onClick={() => setValue("idkURL", null)}
            >
              &times;
            </span>
          </div>
          {errors.idkURL && (
            <label className="invalid-feedback">{errors.idkURL.message}</label>
          )}
        </div>
      </div>
      <div className="modal-footer">
        <button
          className="btn btn-danger"
          type="button"
          data-dismiss="modal"
          onClick={() => closeModal()}
        >
          Cancel
        </button>
        <input className="btn btn-success" type="submit" value="Save" />
      </div>
    </form>
  );
};

QuestionForm.propTypes = {
  currentQuestion: PropTypes.object,
  questionsList: PropTypes.arrayOf(PropTypes.object),
  closeModal: PropTypes.func.isRequired,
  getQuestionsList: PropTypes.func.isRequired,
  pathName: PropTypes.string,
  nextJunctionId: PropTypes.number,
};

export default QuestionForm;
