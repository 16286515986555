import React from "react";
import { Modal, Button } from "react-bootstrap";

import client from "../../../utils/client";

export default (props) => {
  const { show, onHide, handleHutListChanges, id, currentHut } = props;

  const handleDeleteHuts = async () => {
    try {
      await client.delete(`huts/${id}`)      
      onHide();
      handleHutListChanges({ _id: currentHut._id });
    } catch (err) {
      console.error(err);
    }  
  }

  return (
    <Modal
        show={show}
        className="modal fade"
        id="editModal"
        onHide={onHide}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="editModalLabel"
        aria-hidden="true"
    >
      <Modal.Header closeButton>
        <Modal.Title>Delete huts</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Are you sure want to delete?</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>Close</Button>
        <Button variant="danger" onClick={handleDeleteHuts}>Delete</Button>
      </Modal.Footer>
    </Modal>
  )
}