import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_KEY;

const client = new axios.create({
  baseURL: `${BASE_URL}/api/v1/admin/`,
});

const fetchClient = async (url, { data, ...options } = {}) => {
  const defaultOptions = {
    url,
    method: data ? "POST" : "GET",
    data: data ? data : undefined,
    ...options,
  };
  try {
    const { statusText, data: results } = await client({
      ...defaultOptions,
    });
    if (statusText === "OK") {
      return Promise.resolve(results.data);
    }
  } catch (err) {
    return Promise.reject(err.message);
  }
};

export { client as default, fetchClient };
