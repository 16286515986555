export default function buildQuestionsTree(arr) {
  let tree = [];
  const mappedArr = {};

  // First map the nodes of the array to an object -> create a hash table.
  for (let node of arr) {
    mappedArr[node.currentNode] = node;
    mappedArr[node.currentNode]["children"] = [];
  }

  for (let currentIndex in mappedArr) {
    let node = mappedArr[currentIndex];
    // If the element is not at the root level, add it to its parent array of children.
    if (node.previousNode) {
      // Is this Node in a Yes or No branch?
      const parentNode = mappedArr[node.previousNode];
      if (parentNode?.yes?.nextNode === node.currentNode) {
        node.questionType = "yes";
      } else {
        node.questionType = "no";
      }
      mappedArr[node["previousNode"]]["children"].push(node);
    } else {
      // If the element is at the root level, add it to first level elements array
      tree.push(node);
    }
  }

  return tree;
}
