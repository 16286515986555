import * as React from "react";
import { IoMdCreate, IoMdTrash } from "react-icons/io";
import { useForm, useFieldArray } from "react-hook-form";
import clsx from "clsx";

const EditBestPractice = ({
  bestPracticeToEdit,
  closeModal,
  saveHutCheckList,
}) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...bestPracticeToEdit,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "listItems",
  });

  // hasErrors: Number -> String -> Boolean
  const hasErrors = (idx, prop) => {
    if (!errors.listItems) return false;

    if (idx in errors.listItems) {
      const erroredKeys = errors.listItems[`${idx}`];
      return prop in erroredKeys;
    }

    return false;
  };

  const submitChanges = (data) => {
    saveHutCheckList(data);
  };

  return (
    <form onSubmit={handleSubmit(submitChanges)}>
      <h2>Hut Checklist Edit:</h2>
      <div>
        <div className="mt-2 mb-2">
          <p className="mt-1 mb-1 font-weight-bold">Title:</p>
          <input
            {...register("title", {
              required: true,
              validate: {
                noEmptySpaces: (v) => v.trim().length !== 0,
              },
            })}
            className={clsx({
              "form-control mt-1": true,
              "is-invalid": errors.title,
            })}
            type="text"
          />
          {errors.title && (
            <p className="invalid-feedback">This field is required</p>
          )}
        </div>
        <div className="d-flex justify-content-between align-items-center mt-2 mb-2">
          <p className="huts-modal__item-heading">List item:</p>
          <button
            type="button"
            className="btn huts-modal__button"
            onClick={() => append({ title: "", details: "" })}
          >
            New Item
          </button>
        </div>
        {fields.map((listItem, idx) => (
          <div key={listItem.id}>
            <div className="d-flex justify-content-between mt-2">
              <p className="huts-modal__item-heading">{listItem.title}</p>
              <div>
                <button
                  className="bg-success text-white border border-success rounded mr-1"
                  type="button"
                  data-toggle="collapse"
                  data-target={"#collapse" + idx}
                  aria-expanded="false"
                  aria-controls={"collapse" + idx}
                >
                  <IoMdCreate />
                </button>
                <button
                  className="bg-danger text-white border border-danger rounded ml-2"
                  onClick={() => remove(idx)}
                >
                  <IoMdTrash />
                </button>
              </div>
            </div>
            <div className="collapse" id={"collapse" + idx}>
              <div>
                <p className="mt-1 mb-1 font-weight-bold">Title:</p>
                <input
                  {...register(`listItems.${idx}.title`, {
                    required: true,
                    validate: {
                      noEmptySpaces: (v) => v.trim().length !== 0,
                    },
                  })}
                  className={clsx({
                    "form-control mt-1": true,
                    "is-invalid": hasErrors(idx, "title"),
                  })}
                  type="text"
                  defaultValue={listItem.title}
                />
                {hasErrors(idx, "title") && (
                  <p className="invalid-feedback">This field is required</p>
                )}
                <p className="mt-1 mb-1 font-weight-bold">Details:</p>
                <textarea
                  {...register(`listItems.${idx}.details`, {
                    required: true,
                    validate: {
                      noEmptySpaces: (v) => v.trim().length !== 0,
                    },
                  })}
                  className={clsx({
                    "form-control mt-1": true,
                    "is-invalid": hasErrors(idx, "details"),
                  })}
                  rows={4}
                  style={{
                    width: "100%",
                  }}
                  defaultValue={listItem.details}
                />
                {hasErrors(idx, "details") && (
                  <p className="invalid-feedback">This field is required</p>
                )}
              </div>
            </div>
          </div>
        ))}
        <div className="modal-footer mt-1">
          <button
            type="button"
            className="modal-footer__button--close"
            data-dismiss="modal"
            onClick={() => closeModal()}
          >
            Cancel
          </button>
          <input
            type="submit"
            className="modal-footer__button--save"
            value="Save"
          />
        </div>
      </div>
    </form>
  );
};

export default EditBestPractice;
