import * as React from "react";
import { Modal } from "react-bootstrap";
import { fetchClient } from "./../../../utils/client";
import "./style.scss";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";

const hutAssignedToGlossary = (hutId, hutsList) => {
  return hutsList.find((hut) => hut.value === hutId);
};

const selectedHutsList = (glossaryHuts, hutsList) => {
  const selectedHuts = [];
  glossaryHuts.forEach((hut) => {
    const assignedHut = hutAssignedToGlossary(hut._id, hutsList);
    selectedHuts.push(assignedHut);
  });

  return selectedHuts;
};

const GlossaryModal = ({ data = { huts: [] }, huts, ...props }) => {
  const { register, control, handleSubmit } = useForm({
    defaultValues: {
      _id: data?._id ? data._id : null,
      term: data?.term ? data.term : "",
      definition: data?.definition ? data.definition : "",
      huts: data?.huts?.length !== 0 ? selectedHutsList(data.huts, huts) : [],
    },
  });

  const onSubmit = (data) => {
    const hutsId = data.huts.map((hut) => hut.value);
    data.huts = hutsId;

    if (data._id) {
      const { _id: glossaryId, ...rest } = data;
      fetchClient(`glossaries/${glossaryId}`, {
        method: "PUT",
        data: {
          ...rest,
        },
      })
        .then((res) => window.location.reload())
        .catch((err) => console.log(err.message));
    } else {
      delete data._id;
      fetchClient("glossaries", {
        data: {
          ...data,
        },
      })
        .then((res) => window.localtion.reload())
        .catch((err) => console.log(err.message));
    }
  };

  return (
    <Modal
      {...props}
      className="modal fade"
      id="editModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="editModalLabel"
      aria-hidden="true"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>
              {props.type === "edit" ? "Edit Glossary" : "Create Glossary"}
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-4">
            <div className="px-2 py-1 mb-2">
              <p className="huts-modal__item-heading">Term:</p>
              <input
                {...register("term", { required: true })}
                className="form-control"
                type="text"
              />
              <p className="huts-modal__item-heading">Definition:</p>
              <textarea
                {...register("definition", { required: true })}
                className="form-control"
                rows="4"
              />
            </div>
          </div>
          <div className="mb-4">
            <h6>Huts</h6>
            <Controller
              name="huts"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <Select
                    options={huts}
                    value={value}
                    onChange={onChange}
                    isClearable
                    isMulti
                  />
                );
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <input
            type="submit"
            className="modal-footer__button--save"
            value="Save"
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default GlossaryModal;
