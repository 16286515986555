import React, { useEffect, useState } from "react";

import LCNEdit from "./HutsLCNEdit";
import client from "../../../utils/client";

const LawyerCanHelp = ({ closeModal, id, hutId }) => {
  const [lawyerCanHelpItem, setLawyerCanHelpItem] = useState();
  const lcnInfo = {
    helpName: "lawyerHelp",
    helpTitle: "Here’s what a lawyer can do to help",
    valueName: "value",
    valueTitle: "Here’s the value a lawyer can bring to your business",
    risksName: "risks",
    risksTitle: "Here are the risks of doing it yourself",
  };

  useEffect(() => {
    getLawyerCanHelpItem(id);
  }, [id]);

  async function getLawyerCanHelpItem(id) {
    try {
      if (id) {
        const res = await client.get(`lawyer-help-entries/${id}`);
        delete res.data.data.createdAt;
        delete res.data.data.updatedAt;
        delete res.data.data.__v;
        setLawyerCanHelpItem(res.data.data);
      } else {
        setLawyerCanHelpItem({
          details: "trademark issue",
          hut: hutId,
          lawyerHelp: [],
          risks: [],
          value: [],
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function saveHutInformation() {
    try {
      let lwyId = id;
      if (lawyerCanHelpItem) {
        lawyerCanHelpItem.value.map((lwyer) => delete lwyer.isTitle);
      }
      if (id) await client.put(`lawyer-help-entries/${id}`, lawyerCanHelpItem);
      else {
        const res = await client.post(`lawyer-help-entries`, lawyerCanHelpItem);
        lwyId = res.data.data._id;
      }
      closeModal(lwyId, hutId);
    } catch (err) {
      console.error(err);
    }
  }

  function handleChanges(item, blockName) {
    lawyerCanHelpItem[blockName] = item;
    setLawyerCanHelpItem({ ...lawyerCanHelpItem });
  }

  return (
    <>
      <div>
        <h2>Lawyer Can Help Edit:</h2>
        <div>
          <LCNEdit
            content={lawyerCanHelpItem?.lawyerHelp}
            blockName={lcnInfo.helpName}
            blockTitle={lcnInfo.helpTitle}
            handleChanges={handleChanges}
          />
          <LCNEdit
            content={lawyerCanHelpItem?.value}
            blockName={lcnInfo.valueName}
            blockTitle={lcnInfo.valueTitle}
            handleChanges={handleChanges}
          />
          <LCNEdit
            content={lawyerCanHelpItem?.risks}
            blockName={lcnInfo.risksName}
            blockTitle={lcnInfo.risksTitle}
            handleChanges={handleChanges}
          />
        </div>
        <div className="modal-footer mt-2">
          <input
            type="submit"
            className="modal-footer__button--save"
            value="Save"
            onClick={(e) => {
              saveHutInformation(e);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default LawyerCanHelp;
