import React, { useEffect, useState } from "react";
import { fetchClient } from "../../../utils/client";

import EditBestPractice from "./EditBestPractice";
import CreateBestPractice from "./CreateBestPractice";

import "./index.scss";

const BestPracticeUpdateModel = ({
  _id,
  __v,
  createdAt,
  updatedAt,
  hut,
  ...rest
}) => ({ ...rest });

function HutsChecklist({ hutId, hutsList, closeModal, successCallback }) {
  const [bestPractice, setBestPractice] = useState();

  useEffect(() => {
    fetchClient(`/best-practices/hut/${hutId}`)
      .then(([bestPractice]) => {
        setBestPractice(bestPractice);
      })
      .catch((err) => console.log(err.message));
  }, [hutId]);

  async function saveHutCheckList(data) {
    if (bestPractice) {
      const dataForUpdate = BestPracticeUpdateModel(data);
      fetchClient(`best-practices/${bestPractice._id}`, {
        method: "PUT",
        data: dataForUpdate,
      })
        .then((res) => successCallback())
        .catch((err) => console.log(err.message));
    } else {
      data.hut = hutId;
      fetchClient("best-practices", {
        method: "POST",
        data,
      })
        .then((res) => successCallback())
        .catch((err) => console.log(err.message));
    }
  }

  return (
    <div className="huts-modal">
      {bestPractice ? (
        <EditBestPractice
          bestPracticeToEdit={bestPractice}
          closeModal={closeModal}
          saveHutCheckList={saveHutCheckList}
        />
      ) : (
        <CreateBestPractice
          closeModal={closeModal}
          saveHutCheckList={saveHutCheckList}
        />
      )}
    </div>
  );
}

export default HutsChecklist;
