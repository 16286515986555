import React from "react";
import "./style.scss";
import client from "./client";
import useAsync from "../../hooks";

import Select from "../Select";

const fetchAllScenarios = () => {
  return client.get("/scenario");
};

function Home() {
  const stateOptions = ["CA", "TX", "NY"];
  const { status, value, error } = useAsync(fetchAllScenarios);

  function handleSelectedOption(item) {
    console.log(item);
  }

  if (status === "pending") return <p>Fetching scenarios</p>;
  if (status === "error") return <p>{error.message}</p>;

  return (
    <div>
      <div className="home">
        <h1 className="text-center">Legalucy admin</h1>
      </div>
      <div>
        <div className="home__select">
          <p className="font-weight-bold">Select a state:</p>
          <Select
            selectOptions={stateOptions}
            handleSelectOption={handleSelectedOption}
            disabled
          />
        </div>
        <div className="home__select">
          <p className="font-weight-bold">Select a scenario:</p>
          <Select
            selectOptions={value?.data?.data || []}
            handleSelectOption={handleSelectedOption}
            type={"scenarios"}
          />
        </div>
      </div>
    </div>
  );
}

export default Home;
