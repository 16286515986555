import React, { useState } from "react";
import {
  IoMdTrash,
  IoMdCreate,
  IoIosInformationCircleOutline,
} from "react-icons/io";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import clsx from "clsx";

function Question({
  question,
  openModal,
  deleteQuestion,
  setPreviousNode,
  setQuestionType,
  setCurrentQuestion,
}) {
  const [isRemovingQuestion, setIsRemovingQuestion] = useState(false);

  const nestedQuestions = (question.children || []).map((question, i) => {
    return (
      <div className="wrapper d-flex flex-column align-items-center" key={i}>
        <Question
          question={question}
          openModal={openModal}
          deleteQuestion={deleteQuestion}
          setPreviousNode={setPreviousNode}
          setQuestionType={setQuestionType}
        />
      </div>
    );
  });

  return (
    <div className="d-flex flex-column rounded m-3 p-3" style={{}}>
      <div className="p-3 rounded border" style={{ width: "202px" }}>
        <div>Junction {question.currentNode}</div>
        <div className="details-hint-icon">
          <IoIosInformationCircleOutline />
          <div className="hint">{question.details}</div>
        </div>

        <div className="d-flex mb-1 justify-content-center">
          <button
            className={clsx({
              "text-white rounded mr-2": true,
              "bg-primary border border-primary": question?.yes?.nextNode,
              "bg-secondary border border-secondary": !question?.yes?.nextNode,
              "cursor-disabled": question?.yes.nextNode === 222222,
            })}
            type="button"
            data-toggle="modal"
            data-target="#editModal"
            onClick={() => {
              setQuestionType("yes");
              setPreviousNode(question.currentNode);
              openModal({ currentNode: question.yes.nextNode });
            }}
            disabled={question?.yes.nextNode === 222222}
          >
            Yes
          </button>
          <button
            className={clsx({
              "text-white rounded mr-2": true,
              "bg-info border border-info": question?.no?.nextNode,
              "bg-secondary border border-secondary": !question?.no?.nextNode,
              "cursor-disabled": question?.no.nextNode === 222222,
            })}
            onClick={() => {
              setQuestionType("no");
              setPreviousNode(question.currentNode);
              openModal({ currentNode: question.no.nextNode });
            }}
            disabled={question?.no.nextNode === 222222}
          >
            No
          </button>
          <button
            className="bg-success text-white border border-success rounded ml-2"
            onClick={() => {
              openModal({ id: question._id });
            }}
          >
            <IoMdCreate />
          </button>
          <button
            className="bg-danger text-white border border-danger rounded ml-2"
            onClick={() => setIsRemovingQuestion(true)}
          >
            <IoMdTrash />
          </button>
        </div>
      </div>
      <div className="d-flex mt-3 border-top">{nestedQuestions}</div>
      {isRemovingQuestion && (
        <Modal
          className="modal fade"
          show={isRemovingQuestion}
          onHide={() => setIsRemovingQuestion(false)}
          id="deleteModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="deleteModal"
          aria-hidden="true"
        >
          <Modal.Header closeButton>
            <Modal.Title>Remove pathway</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {question.currentNode === 1 ? (
              <p>Warning, removing root Pathway will remove the entire tree</p>
            ) : (
              <p>Are you sure you want to remove this pathway?</p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setIsRemovingQuestion(false)}
            >
              Close
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                deleteQuestion(question._id);
                setIsRemovingQuestion(false);
              }}
            >
              Delete Pathway
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}

export default Question;
