import React, { useState, useContext, createContext } from "react";
import client from "../utils/client";

const authContext = createContext();
// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};
// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState({ accessToken: localStorage.getItem("accessToken")});
  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const signin = async (password) => {
    const result = await client.post(`authentication/login`, {password});   
    setUser(result.data);
    localStorage.setItem("accessToken", result.data.token)
    return result.data;    
  };
  
  // Return the user object and auth methods
  return {
    user,
    signin,
  };
}