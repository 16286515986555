import * as React from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import client from "../../../utils/client";

const searchHutOption = (hutOptions, hutId) => {
  return hutOptions.find((hutOption) => hutOption.value === hutId);
};

function ServicesModal({
  service,
  hutsList,
  closeModal,
  handleChanges,
  productId,
}) {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      productName: service?.productName ? service.productName : "",
      productDescription: service?.productDescription
        ? service.productDescription
        : "",
      hut: service?.hut ? searchHutOption(hutsList, service.hut._id) : null,
      itemNumber: service?.itemNumber ? service.itemNumber : productId,
    },
  });

  async function editServiceRequest(data, id) {
    try {
      await client.put(`products/${id}`, data);
      handleChanges();
      closeModal();
    } catch (err) {
      console.error(err);
    }
  }

  async function createNewServiceRequest(data) {
    try {
      await client.post(`products`, data);
      handleChanges();
      closeModal();
    } catch (err) {
      console.error(err);
    }
  }

  //Form submission
  const onSubmit = (data) => {
    const serviceData = {
      ...data,
      hut: data.hut.value,
    };
    service?._id
      ? editServiceRequest(serviceData, service._id)
      : createNewServiceRequest(serviceData);
  };

  return (
    <div>
      <div>
        <h2>
          {service ? `Editing (${service.productName})` : "Create Service"}
        </h2>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-2">
          <span>Name:</span>
          <input
            className="form-control"
            type="text"
            {...register("productName", {
              required: true,
              maxLength: 250,
              minLength: 3,
            })}
          />
          <span className="error">
            {errors.productName &&
              errors.productName.type === "required" &&
              "Name is required"}
            {errors.productName &&
              errors.productName.type === "maxLength" &&
              "Name cannot be longer than 250"}
            {errors.productName &&
              errors.productName.type === "minLength" &&
              "Name is too short"}
          </span>
        </div>
        <div className="mt-2">
          <span>Description:</span>
          <textarea
            className="form-control"
            type="text"
            rows="4"
            {...register("productDescription", {
              required: true,
              maxLength: 500,
              minLength: 3,
            })}
          />
          <span className="error">
            {errors.productDescription &&
              errors.productDescription.type === "required" &&
              "Description is required"}
            {errors.productDescription &&
              errors.productDescription.type === "maxLength" &&
              "Description cannot be longer than 500"}
            {errors.productDescription &&
              errors.productDescription.type === "minLength" &&
              "Description is too short"}
          </span>
        </div>
        <div className="mt-2">
          <span>Hut:</span>
          <Controller
            name="hut"
            control={control}
            render={({ field }) => {
              return (
                <Select
                  options={hutsList}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                />
              );
            }}
            rules={{ required: true }}
          />
          <span className="error">
            {errors.hut &&
              errors.hut.type === "required" &&
              "You must select a Hut"}
          </span>
        </div>
        <div className="modal-footer path-steps-modal__item">
          <button
            type="button"
            className="modal-footer__button--close"
            data-dismiss="modal"
            onClick={() => closeModal()}
          >
            Cancel
          </button>
          <input
            type="submit"
            className="modal-footer__button--save"
            value="Save"
          />
        </div>
      </form>
    </div>
  );
}

export default ServicesModal;
