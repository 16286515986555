import React, { useState, useEffect } from "react";
import client from "../../utils/client";
import "./style.scss";

import PathStepsModal from "./PathStepsModal";
import { IoMdCreate, IoMdTrash } from "react-icons/io";
import Modal from "react-bootstrap/Modal";

function PathSteps() {
  const [showPathStepsModal, setShowPathStepsModal] = useState(false);
  const [currentPathStep, setCurrentPathStep] = useState("");
  const [pathSteps, setPathSteps] = useState([]);
  const [stepIds, setStepsId] = useState([]);

  useEffect(() => {
    getPathStepsList();
  }, []);

  function handlePathStepsChanges() {
    getPathStepsList();
  }

  function openModal(id) {
    setShowPathStepsModal(true);
    id
      ? setCurrentPathStep(pathSteps.find((hut) => hut._id === id))
      : setCurrentPathStep({});
  }

  function closeModal() {
    setTimeout(setShowPathStepsModal(false), 1000);
  }

  async function getPathStepsList() {
    try {
      const res = await client.get(`path-steps`);
      const steps = res.data.data;
      var result = steps.map((step) => step.step);
      setStepsId(result);

      let list = res.data.data;
      list.sort((a, b) => (a.step > b.step) ? 1 : -1)
      setPathSteps(list);      
    } catch (err) {
      console.log(err);
    }
  }

  async function deletePathStep(id) {
    try {
      await client.delete(`path-steps/${id}`);          
      getPathStepsList();
    } catch (err) {
      console.error(err);
    }
  }

  function deleteConfirm(id) {
    if (window.confirm("Are you sure to delete?")) {
      // Delete it
      deletePathStep(id);
    }
  }

  return (
    <div className="path-steps">
      <div className="header">
        <h1>Entryway</h1>
        <div className="d-flex justify-content-between">
          <div>
            <p className="font-weight-bold mt-3">Scenario:</p>
            <select className="form-control d-flex path-steps__select">
              <option>7 Steps and You’re in Business</option>
            </select>
          </div>
          <div className="path-steps__menu">
            <div className="text-center mt-3 path-steps__button--create">
              <button
                className="btn path-steps__button"
                type="button"
                data-toggle="modal"
                data-target="#editModal"
                onClick={() => openModal()}
              >
                Create +
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="path-steps">
        {pathSteps.map((item, i) => {
          return (
            <div className="path-steps__item" key={i}>
              <div className="path-steps__item-header">
                <h4 className="path-steps__item-heading">{item.name}</h4>
                <div className="path-steps__item-buttons">
                  <button
                    className="bg-success text-white border border-success rounded"
                    type="button"
                    data-toggle="modal"
                    data-target="#editModal"
                    onClick={() => openModal(item._id)}
                  >
                    <IoMdCreate />
                  </button>
                  <div className="path-steps__item-buttons--delete">
                    {/*  TODO: uncomment when backend will be ready <div className="overlay"></div> */}
                    <button
                      className="bg-danger text-white border border-danger rounded"
                      onClick={() => deleteConfirm(item._id)}
                    >
                      <IoMdTrash />
                    </button>
                  </div>
                </div>
              </div>
              <div className="path-steps__item-main">
                <p className="path-steps__item-info">
                  Step #: <span>{item.step}</span>
                </p>
                <p className="path-steps__item-info">
                  Description A: <span>{item.title}</span>
                </p>
                <p className="path-steps__item-info">
                  Description B: <span>{item.description}</span>
                </p>
                <p className="path-steps__item-info">
                  Active: <span>{item.active ? "+" : "-"}</span>
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <Modal
        show={showPathStepsModal}
        className="modal fade"
        id="editModal"
        onHide={() => setShowPathStepsModal(false)}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="editModalLabel"
        aria-hidden="true"
      >
        <Modal.Body>
          <PathStepsModal
            closeModal={closeModal}
            pathStep={currentPathStep}
            handlePathStepsChanges={handlePathStepsChanges}
            stepIds={stepIds}
          ></PathStepsModal>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default PathSteps;
