import * as React from "react";

import client from "../../utils/client";

import { IoMdCreate, IoMdTrash } from "react-icons/io";
import Modal from "react-bootstrap/Modal";
import ServicesModal from "./ServicesModal";
import Table from "./Table";

import "./index.scss";

function Services() {
  const [services, setServices] = React.useState();
  const [hutList, setHutsList] = React.useState();
  const [currentService, setCurrentService] = React.useState();
  const [showModal, setShowModal] = React.useState("none");
  const [productId, setProductId] = React.useState("00001");
  const columns = React.useMemo(() => {
    return [
      {
        Header: "Name",
        accessor: "productName",
        disableFilters: true,
      },
      {
        Header: "Hut",
        accessor: "hut.hutSubType",
        disableSortBy: true,
        filter: "fuzzyText",
        Cell: ({
          cell: {
            row: { original: item },
          },
        }) => <p>{`${item?.hut?.hutSubType} (${item?.hut?.pathName.name})`}</p>,
      },
      {
        Header: "Actions",
        Cell: ({
          cell: {
            row: { original: item },
          },
        }) => {
          return (
            <>
              <button
                className="bg-success text-white border border-success rounded"
                type="button"
                onClick={() => openModal(item._id, item)}
              >
                <IoMdCreate />
              </button>
              <button
                className="bg-danger text-white border border-danger rounded ml-1"
                onClick={() => deleteConfirm(item._id)}
              >
                <IoMdTrash />
              </button>
            </>
          );
        },
      },
    ];
  }, []);

  React.useEffect(() => {
    getServicesList();
    getHutsList();
  }, []);

  function deleteConfirm(id) {
    if (window.confirm("Are you sure to delete?")) {
      // Delete it
      deleteService(id);
    }
  }

  async function getServicesList() {
    try {
      const {
        data: { data: services },
      } = await client.get(`products`);
      setServices(services);
      if (services.length !== 0) {
        const results = services;
        results.sort(
          (a, b) => parseInt(a.itemNumber, 10) - parseInt(b.itemNumber, 10)
        );
        const lastItem = results[results.length - 1];
        let maxId = parseInt(lastItem.itemNumber) + 1;
        let str = maxId.toString().padStart(5, "0");
        setProductId(str);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function getHutsList() {
    try {
      const params = new URLSearchParams();
      const $sort = {
        hutSubType: 1,
      };
      params.append("$sort", JSON.stringify($sort));
      const {
        data: { data: huts },
      } = await client.get(`huts?${params.toString()}`);
      const hutOptions = huts
        .sort(({ pathName: { name: nameA } }, { pathName: { name: nameB } }) =>
          nameA.localeCompare(nameB)
        )
        .map((hut) => {
          return {
            value: hut._id,
            label: `${hut.pathName.name}: ${hut.hutType} (${hut.hutSubType})`,
          };
        });
      setHutsList(hutOptions);
    } catch (err) {
      console.log(err);
    }
  }

  const openModal = (id, serviceToEdit) => {
    if (id) {
      setShowModal("edit");
      setCurrentService(serviceToEdit);
    } else {
      setShowModal("create");
    }
  };

  function handleChanges() {
    getServicesList();
  }

  async function deleteService(id) {
    try {
      await client.delete(`products/${id}`);
      handleChanges();
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <div className="services">
      <div className="header d-flex justify-content-between">
        <h2>Services</h2>
        <div>
          <button
            className="btn services__button"
            type="button"
            data-toggle="modal"
            data-target="#createModal"
            onClick={() => openModal()}
          >
            Create +
          </button>
        </div>
      </div>
      <div>{services && <Table columns={columns} data={services} />}</div>
      <Modal
        show={showModal === "edit"}
        className="modal fade"
        id="editModal"
        onHide={() => setShowModal("none")}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="editModalLabel"
        aria-hidden="true"
      >
        <Modal.Body>
          <ServicesModal
            closeModal={() => setShowModal("none")}
            service={currentService}
            hutsList={hutList}
            handleChanges={handleChanges}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={showModal === "create"}
        className="modal fade"
        id="createModal"
        onHide={() => setShowModal("none")}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="createModalLabel"
        aria-hidden="true"
      >
        <Modal.Body>
          <ServicesModal
            closeModal={() => setShowModal("none")}
            hutsList={hutList}
            handleChanges={handleChanges}
            productId={productId}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Services;
