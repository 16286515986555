import React from "react";
import "./style.scss";

const FAQ = () => {
  return (
    <div>
      <div className="faq">
        <h1 className="text-center">Legalucy admin</h1>
      </div>
      <div className="mt-5">
        <div>
          <span className="font-weight-bold">Scenarios:</span> 7 Entryway and
          You’re in Business
        </div>
        <div>
          <span className="font-weight-bold">State:</span> California
        </div>
        <div>
          <p className="font-weight-bold mt-3">Instructions: (IN PROGRESS)</p>
          <ol>
            <li>Create or Select a Step from the drop down list</li>
            <li>Create or update Questions for that Step</li>
            <li>
              Create or update Huts Information for that Step, Hut
              Information Includes Glossaries, How a lawyer can help and
              Selecting/Assigning services for that Hut
            </li>
            <li>
              Create or update or Assign Services to each Hut, Services are the
              same as Related products
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
