import React from "react";
import "./style.scss";
import { NavLink } from "react-router-dom";

function Sidebar() {
  return (
    <nav className="sidebar">
      <ul className="sidebar__links-list links-list">
        <li className="links-list__item item">
          <NavLink exact to="/" activeClassName="selected">
            Home
          </NavLink>
        </li>
        <li className="blank-menu"></li>
        <li className="links-list__item item">
          <NavLink to="/steps" activeClassName="selected">
            Entryway
          </NavLink>
        </li>
        <li className="links-list__item item">
          <NavLink to="/questions" activeClassName="selected">
          Pathway
          </NavLink>
        </li>
        <li className="links-list__item item">
          <NavLink to="/huts" activeClassName="selected">
            Huts
          </NavLink>
        </li>
        <li className="links-list__item item pad-left">
          <NavLink to="/services" activeClassName="selected">
            Master Services
          </NavLink>
        </li>
        <li className="links-list__item item pad-left">
          <NavLink to="/glossary" activeClassName="selected">
            Master Glossary Terms
          </NavLink>
        </li>
        <li className="blank-menu"></li>
        <li className="links-list__item item">
          <NavLink exact to="/faq" activeClassName="selected">
            How it works
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}

export default Sidebar;
